<template>
  <b-container fluid>
    <form>
      <iq-card>
        <template v-slot:headerTitle>
          <h4>{{ $t("GeneralSettings") }}</h4>
        </template>
        <template v-slot:body>
          <b-row>
            <b-col md="3" class="text-right">
              <label> {{ $t("Logo") }} </label>
            </b-col>
            <b-col md="5">
              <div style="position:relative; display:inline-block;">
                <div id="imageHolder" class="kt-avatar__holder"></div>
                <label class="rounded-circle iq-card-icon iq-bg-primary" for="fileInput" style="position: inherit; width: 40px; height: 40px; line-height: 40px; font-size: 15px; margin-bottom: 0px; bottom: 166px;
         left: 181px;">
                  <i class="fa fa-pen"></i>
                </label>
                <input id="fileInput" type="file" style="display:none" @change="onFileChanged" />
                <label class="rounded-circle iq-card-icon iq-bg-danger" @click="onRemove" style="position: inherit; width: 40px; height: 40px; line-height: 40px; font-size: 15px; margin-bottom: 0px; bottom: 20px;
         left: 140px;">
                  <i class="fa fa-times"></i>
                </label>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3" class="text-right">
              <h3 class="checkboxText"> {{ $t('StartTime') }}  -  {{$t('EndTime')}} </h3>
            </b-col>
            <time-picker v-model="model.workStartTime"
                         :is-required="false">
            </time-picker>
            <i style="margin:10px" class="fa fa-minus"></i>
            <time-picker v-model="model.workEndTime"
                         :is-required="false">
            </time-picker>
          </b-row>
          <b-row>
            <b-col style="text-align:center" md="7">
              <span class="text-danger error-mes"
                    v-if="message"
                    v-text="message" />
            </b-col>
          </b-row>
        </template>
      </iq-card>
      <modal-footer-button @submit="submitGeneralSettings"></modal-footer-button>
    </form>
  </b-container>
</template>
<script>
  import managementServices from '../../../services/management';
  import { refreshMultimediaData } from '../../../helpers/dataRefresher';

  import ModalFooterButton from '../../../components/shared/ModalFooterButton';
  import TimePicker from '../../../components/shared/TimePicker'
  export default {
    name: 'GeneralSettings',
    components: {
      ModalFooterButton,
      TimePicker,
    },
    data() {
      return {
        model: {
          workStartTime: '',
          workEndTime: '',
          appointmentAutoChangeStatus: true,
          allowDuplicateAppointmentTime: false,
          appointmentAvailableType: 'availablestaffandroom'
        },
        logo: '',
        timesAreValid: true,
        selectedFile: '',
        message: '',
      }
    },
    methods: {
      getGeneralSettings() {
        managementServices.getGeneralSettings().then((response) => {
          if (response) {
            this.model = response;
            this.model.appointmentAutoChangeStatus = true;
            this.model.allowDuplicateAppointmentTime = false;
            this.model.appointmentAvailableType = 'availablestaffandroom';
            this.model.allowSellOutOfStockProduct = true;
          }
        });
      },
      getLogo() {
        managementServices.getLogo().then((response) => {
          if (response) {
            this.logo = response.thumbnailUrl;
          }
        }).finally(() => {
          let imageHolder = document.getElementById("imageHolder");
          imageHolder?.setAttribute(
            "style",
            "background-image:url(" +
            this.logo +
            ");background-size:contain;background-color:#2F373E;width:200px;height:150px;background-position: center"
          );
        });
      },
      onFileChanged(event) {
        this.selectedFile = event.target.files[0];
        this.onUpload();
      },
      onUpload() {
        const formData = new FormData();
        formData.append("logo", this.selectedFile, this.selectedFile.name);

        managementServices.submitLogo(formData).then((response) => {
          if (response) {
            this.$toastr.success(this.$t("Success"));
            this.logo = response.message;

            let imageHolder = document.getElementById("imageHolder");
            imageHolder.setAttribute(
              "style",
              "background-image:url(" +
              this.logo +
              ");background-size:contain;background-color:#2F373E;width:200px;height:150px;background-position: center"
            );
          } else {
            this.$toastr.error(this.$t("Error"));
          }
        })
      },
      async submitGeneralSettings() {
        if (this.timesAreValid) {
          managementServices.submitGeneralSettings(this.model).then(response => {
            if (response == true) {
              this.$toastr.success(this.$t("Success"));

              if (this.model.workStartTime && this.model.workStartTime) {
                this.$store.dispatch('Calendar/setCalendarStartTime', this.model.workStartTime);
                this.$store.dispatch('Calendar/setCalendarEndTime', this.model.workEndTime);
              }

              refreshMultimediaData();
            }
            else {
              this.$toastr.error(this.$t("Error"));
            }
          });
        }
      },
      onRemove() {
        managementServices.removeLogo().then((response) => {
          if (response) {
            this.$toastr.success(this.$t("Success"));
            let imageHolder = document.getElementById("imageHolder");
            imageHolder.style.backgroundImage = ""; 
          } else {
            this.$toastr.error(this.$t("Error"));
          }
        });
      },
    },
    computed: {
      token() {
        return this.$store.getters['Auth/token'];
      },
      dropzoneOptions() {
        return {
          url: "/Multimedia/UploadFile",
          paramName: "file",
          thumbnailWidth: 150,
          maxFiles: 40,
          maxFilesize: 500,
          addRemoveLinks: true,
          dictDefaultMessage: this.$t("DropFilesHereToUpload"),
          dictRemoveFile: this.$t("Remove"),
          autoProcessQueue: true,
          parallelUploads: 1,
          headers: {
            "Authorization": "Bearer " + this.token,
            "groupId": this.groupId,
            "objectId": this.objectId,
            "uploadedFrom": "UI"
          },
        };
      }
    },
    watch: {
      "model.workStartTime": function () {
        if (
          this.model.workStartTime &&
          this.model.workEndTime &&
          this.model.workStartTime < this.model.workEndTime
        ) {
          this.timesAreValid = true;
          this.message = "";
        } else if (!this.model.workStartTime && !this.model.workEndTime) {
          this.timesAreValid = true;
          this.message = "";
        } else {
          this.timesAreValid = false;
          this.message = this.$t("StartTimeErrorMessage");
        }
      },
      "model.workEndTime": function () {
        if (
          this.model.workStartTime &&
          this.model.workEndTime &&
          this.model.workEndTime > this.model.workStartTime
        ) {
          this.timesAreValid = true;
          this.message = "";
        } else if (!this.model.workStartTime && !this.model.workEndTime) {
          this.timesAreValid = true;
          this.message = "";
        } else {
          this.timesAreValid = false;
          this.message = this.$t("StartTimeErrorMessage");
        }
      },
    },
    mounted() {
      this.getLogo();
      this.getGeneralSettings();
    }
  }
</script>
<style scoped>
  .checkboxText {
    font-size: 15px;
  }

  #lineSpace {
    margin: 10px;
  }
</style>
