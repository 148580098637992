<template>
  <b-container fluid>
    <ValidationObserver ref="docplannerSettingsObserver" v-slot="{ invalid }">
      <form>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{$t("DocplannerIntegration")}}</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-form-group class="col-md-3" style="text-align: right" :label="$t('Url')" label-for="Url">
              </b-form-group>
              <b-textarea class="col-md-7" v-model="model.baseUri" disabled> </b-textarea>
            </b-row>
            &nbsp;
            <b-row>
              <b-form-group class="col-md-3" style="text-align: right" label-for="clientId" :label="$t('client Id')"></b-form-group>
              <b-textarea class="col-md-7" v-model="model.secretId"></b-textarea>
            </b-row>
            &nbsp;
            <b-row>
              <b-form-group class="col-md-3" style="text-align: right" label-for="ClientKey" :label="$t('Client Key')"></b-form-group>
              <b-textarea class="col-md-7" v-model="model.secretKey"></b-textarea>
            </b-row>
          </template>
        </iq-card>
        <modal-footer-button @submit="submitDocplannerSettings"></modal-footer-button>
      </form>
    </ValidationObserver>
  </b-container>
</template>
<script>

  import managementServices from '../../../services/management';
  import ModalFooterButton from '../../../components/shared/ModalFooterButton';
  export default {
    name: 'FormSettings',
    components: {
      ModalFooterButton
    },
    data() {
      return {
        model: {
          baseUri: "https://www.doktortakvimi.com",
          secretId: '',
          secretKey: '',
        },
      }
    },
    methods: {
      getDocplannerSettings() {
        managementServices.getDocplannerSettings().then((response) => {
          if (response && response != null) {
            this.model = response;
          }
        })
      },
      async submitDocplannerSettings() {
        const isValid = await this.$refs.docplannerSettingsObserver.validate();
        if (!isValid) {
          this.$toastr.error(this.$t('ValidationMessage.EmptyAndValidateError'));
          return;
        }
        managementServices.submitDocplannerSettings(this.model).then((result) => {
          if (result == 1) {
            this.$toastr.success(this.$t('Success'));
          }
          else if (result == -2) {
            this.$toastr.error(this.$t('VegaDpImCredentialsInvalidText'));
          }
          else {
            this.$toastr.error(this.$t('Error'));
          }
        })
      },
    },
    mounted() {
      this.getDocplannerSettings();
    }
  }
</script>

