<template>
  <div>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t("CustomerRegistryPreferences") }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col md="5" class="text-right">
            <h3 class="checkboxText"> {{$t("BlockOnDuplicatePhoneNumber")}} </h3>
          </b-col>
          <b-col md="5">
            <b-form-checkbox v-model="model.blockOnDuplicatePhoneNumber" switch size="lg"></b-form-checkbox>
          </b-col>
        </b-row>
        <br />

        <b-row>
          <b-col md="5" class="text-right">
            <h3 class="checkboxText"> {{ $t("ShowMedicalSection") }} </h3>
          </b-col>
          <b-col md="5">
            <b-form-checkbox v-model="model.showMedicalSection" switch size="lg"></b-form-checkbox>
          </b-col>
        </b-row>
        <br />

        <b-row>
          <b-col md="5" class="text-right">
            <h3 class="checkboxText"> {{ $t("AutoPassiveText") }} </h3>
          </b-col>
          <b-col md="5">
            <b-form-checkbox v-model="model.autoPassive" switch size="lg"> </b-form-checkbox>
          </b-col>
        </b-row>
        <br />

        <b-row>
          <b-col md="5" class="text-right">
            <h3 class="checkboxText"> {{ $t("PassiveBillStamp") }} </h3>
          </b-col>
          <b-col md="5">
            <b-form-input v-model="model.passiveBillStamp" @keypress="$root.isNumber"> </b-form-input>
          </b-col>
        </b-row>
        <br />

        <b-row>
          <b-col md="5" class="text-right">
            <h3 class="checkboxText"> {{ $t("GenderSectionSelectedValue") }} </h3>
          </b-col>
          <b-col md="5">
            <v-select transition="" v-model="model.genderSelectedValue"
                      :reduce="s => s.id" label="name"
                      :options="genderList">
            <template v-slot:no-options> {{$t('NoMatchingOptionsMessage')}} </template> </v-select>
          </b-col>
        </b-row>
      </template>
    </iq-card>

    <iq-card>
      <template v-slot:body>
        <b-alert show dismissible>
          <b>{{$t('CustomerRegistryPreferencesInfoText')}}</b>
        </b-alert>
        <b-row>
          <b-col md="5" class="text-right">
            <h3 class="checkboxText"> {{ $t('IdentityNumberRequired') }}</h3>
          </b-col>
          <b-col md="5">
            <b-form-checkbox v-model="model.citizenshipID_Required" switch size="lg">
            </b-form-checkbox>
          </b-col>
        </b-row>
        <br />

        <b-row>
          <b-col md="5" class="text-right">
            <h3 class="checkboxText"> {{ $t('CityRequired') }} </h3>
          </b-col>
          <b-col md="5">
            <b-form-checkbox v-model="model.cityRequired" switch size="lg"> </b-form-checkbox>
          </b-col>
        </b-row>
        <br />

        <b-row>
          <b-col md="5" class="text-right">
            <h3 class="checkboxText"> {{ $t('CountryRequired') }} </h3>
          </b-col>
          <b-col md="5">
            <b-form-checkbox v-model="model.countryRequired" switch size="lg"> </b-form-checkbox>
          </b-col>
        </b-row>
        <br />

        <b-row>
          <b-col md="5" class="text-right">
            <h3 class="checkboxText"> {{ $t('DateOfBirthRequired') }} </h3>
          </b-col>
          <b-col md="5">
            <b-form-checkbox v-model="model.dateOfBirthRequired" switch size="lg"></b-form-checkbox>
          </b-col>
        </b-row>
        <br />

        <b-row>
          <b-col md="5" class="text-right">
            <h3 class="checkboxText"> {{ $t('EmailRequired') }} </h3>
          </b-col>
          <b-col md="5">
            <b-form-checkbox v-model="model.emailRequired" switch size="lg"> </b-form-checkbox>
          </b-col>
        </b-row>
        <br />

        <b-row>
          <b-col md="5" class="text-right">
            <h3 class="checkboxText"> {{ $t('ReferenceSourceRequired') }} </h3>
          </b-col>
          <b-col md="5">
            <b-form-checkbox v-model="model.referenceSourceRequired" switch size="lg"></b-form-checkbox>
          </b-col>
        </b-row>
        <br />

        <b-row>
          <b-col md="5" class="text-right">
            <h3 class="checkboxText"> {{ $t('AssignCardNoToCustomerAfterInsert') }} </h3>
          </b-col>
          <b-col md="5">
            <b-form-checkbox v-model="model.assignCardNoAfterCustomerCreated" switch size="lg"></b-form-checkbox>
          </b-col>
        </b-row>
      </template>
    </iq-card>

    <modal-footer-button @submit="submitCustomerSettings"></modal-footer-button>
  </div>
</template>
<script>
  import customerServices from '../../../services/customer';
  import managementServices from '../../../services/management';

  import ModalFooterButton from '../../../components/shared/ModalFooterButton';
  export default {
    name: 'CustomerSettings',
    components: {
      ModalFooterButton
    },
    data() {
      return {
        model: {
          blockOnDuplicatePhoneNumber: false,
          citizenshipID_Required: false,
          cityRequired: false,
          countryRequired: false,
          dateOfBirthRequired: false,
          emailRequired: false,
          showMedicalSection: false,
          autoPassive: false,
          passiveBillStamp: null,
          assignCardNoAfterCustomerCreated: false,
          referenceSourceRequired: false,
        },
        genderList: []
      }
    },
    methods: {
      getGenderList() {
        customerServices.getGenders().then((response) => {
          if (response) {
            this.genderList = response;
          }
        })
      },
      getCustomerSettings() {
        customerServices.getCustomerSetting().then((response) => {
          if (response) {
            this.model = response;
          }
        })
      },
      async submitCustomerSettings() {
        managementServices.submitCustomerSettings(this.model).then((response) => {
          if (response) {
            this.$toastr.success(this.$t("Success"));
            //router verildi�inde push at�lmal�..
          } else {
            this.$toastr.error(this.$t("Error"));
          }
        })
      },
    },
    mounted() {
      this.getGenderList();
      this.getCustomerSettings();
    }
  }
</script>

<style scoped>
  .checkboxText {
    font-size: 15px;
  }
</style>
